import { Box, Fade  } from "@mui/material";
import React from "react";

export const HeaderLogo: React.FC<{}> = () => {
  return (
    <Box
      minHeight="100vh"
      maxHeight="100vh"
      display="flex"
      flexDirection="column"
      textAlign="center"
      justifyContent="center"
      id="home"
    >
      <Fade in timeout={4000}>
        <img src={require('../logo_main.png')} alt="" />
      </Fade>
    </Box>
  );
};
