import {
  Box,
  Fade,
  Divider,
  Typography,
  Zoom,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import {
  SiWhatsapp,
  SiTiktok,
  SiInstagram,
  SiGmail,
  SiLinkedin,
} from "@icons-pack/react-simple-icons";

function Contact() {
  const [isVisible, setIsVisible] = useState(false);
  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  const sizeIcons = 60;

  return (
    <div>
      <Box py={10} id="contact">
        <VisibilitySensor onChange={onVisibilityChange}>
          <Fade in={isVisible} timeout={2000}>
            <Divider
              textAlign="left"
              sx={{
                pb: 3,
                "&::before, &::after": {
                  borderColor: "#BCA456",
                },
              }}
            >
              <Typography m={2} variant="h3">
                Contacto
              </Typography>
            </Divider>
          </Fade>
        </VisibilitySensor>

        <Zoom in={isVisible} timeout={1000}>
          <IconButton
            sx={{ mx: 1 }}
            href="mailto:richardandresve@gmail.com"
            target="_blank"
          >
            <SiGmail size={sizeIcons} />
          </IconButton>
        </Zoom>

        <Zoom in={isVisible} timeout={1500}>
          <IconButton
            sx={{ mx: 1 }}
            href="https://wa.me/3176771498"
            target="_blank"
          >
            <SiWhatsapp size={sizeIcons} />
          </IconButton>
        </Zoom>

        <Zoom in={isVisible} timeout={2500}>
          <IconButton
            sx={{ mx: 1 }}
            href="https://www.instagram.com/richardvelasquez_abogado/"
            target="_blank"
          >
            <SiInstagram size={sizeIcons} />
          </IconButton>
        </Zoom>

        <Zoom in={isVisible} timeout={3000}>
          <IconButton
            sx={{ mx: 1 }}
            href="https://www.tiktok.com/@abogadorichardvelasquez"
            target="_blank"
          >
            <SiTiktok size={sizeIcons} />
          </IconButton>
        </Zoom>

        <Zoom in={isVisible} timeout={3500}>
          <IconButton
            sx={{ mx: 1 }}
            href="https://www.linkedin.com/in/richard-andres-velasquez-eraso-4409461b1/"
            target="_blank"
          >
            <SiLinkedin size={sizeIcons} />
          </IconButton>
        </Zoom>
      </Box>
    </div>
  );
}

export default Contact;
