import { Box, Divider, Fade, Typography, Slide } from "@mui/material";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

export const Services: React.FC<{}> = () => {
  const servicesList = [
    "Declaración de ineficacia de la afiliación al RAI y retorno al RMP.",
    "Procesos derivados de la seguridad social en pensiones (pensiones de vejez, invalidez, sobrevivientes, auxilios funerarios, pensión familiar, etc.).",
    "Indemnizaciones por accidentes de tránsito y eventos catastróficos.",
    "Demandas de reparación directa.",
    "Procesos de familia y sucesiones.",
    "Acciones de tutela.",
    "Representación jurídica empresarial en relaciones laborales y contractuales.",
];


  const [isVisible, setIsVisible] = useState(false);
  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };
  return (
    <Box pt={10} id="services">
      <VisibilitySensor onChange={onVisibilityChange}>
        <Fade in={isVisible} timeout={2000}>
          <Divider
            textAlign="left"
            sx={{
              "&::before, &::after": {
                borderColor: "#BCA456",
              },
              pb: 3,
            }}
          >
            <Typography m={2} variant="h3">
              Servicios
            </Typography>
          </Divider>
        </Fade>
      </VisibilitySensor>

      {servicesList.map((item, index) => {
        return (
          <VisibilitySensor key={index} onChange={onVisibilityChange}>
            <Slide direction="right" in={isVisible} timeout={(index + 2) * 500}>
              <Box display="flex" alignItems="center" py={2} px={1}>
                <ChevronRightIcon sx={{ color: "#BCA456", fontSize: 30 }} />
                <Typography variant="h5">{item}</Typography>
              </Box>
            </Slide>
          </VisibilitySensor>
        );
      })}
    </Box>
  );
};
