import {
  Avatar,
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { themePalette } from "../config/theme.config";
import "./BusinessCard.css";

import { WhatsApp, Phone, Email, LocationOn } from "@mui/icons-material";

export default function BusinessCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const iconsStyles = {
    fontSize: 50,
  };

  const iconsInfo = [
    {
      iconElement: <Phone sx={iconsStyles} />,
      label: "Teléfono",
      href: "tel:3176771498",
    },
    {
      iconElement: <Email sx={iconsStyles} />,
      label: "Correo",
      href: "mailto:richardandresve@gmail.com",
    },
    {
      iconElement: <LocationOn sx={iconsStyles} />,
      label: "Pasto, Nariño",
      href: "https://www.google.com/maps/place/Pasto,+Nari%C3%B1o/@1.2135255,-77.2772226,13z/data=!3m1!4b1!4m6!3m5!1s0x8e2ed48761b92a73:0x44a368566cc3a522!8m2!3d1.2058837!4d-77.285787!16zL20vMDNqMzFi?entry=ttu",
    },
    {
      iconElement: <WhatsApp sx={iconsStyles} />,
      label: "Whatsapp",
      href: "https://wa.me/3176771498",
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Box
        className="cardContainer"
        sx={{
          width: isMobile ? "100vw" : "56.25vh",
          height: isMobile ? "100%" : "90vh",
          alignItems: "center",
          justifyContent: "center",
          boxShadow: "0px 4px 40px rgba(0, 0, 0, 1)",
          padding: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "25%",
          }}
        >
          <Avatar
            src={require("../logo.png")}
            alt="imagen circular"
            sx={{
              width: 250, // Cambia el tamaño según necesites
              height: 250, // Cambia el tamaño según necesites
              borderRadius: "0",
              //border: `1px solid ${themePalette.MAIN_COLOR}`,
            }}
          />
        </Box>
        <Box
          sx={{
            m: 3,
            textAlign: "center",
          }}
        >
          <Typography variant="h4" className="shine-text">
            Richard <strong>Velásquez</strong>
          </Typography>
          <Typography variant="h5" color={themePalette.MAIN_COLOR}>
            Abogado
          </Typography>
        </Box>
        {/* Botones */}
        <Box>
          <Grid container spacing={1} px={2}>
            {iconsInfo.map((icon, i) => {
              return (
                <Grid
                  item
                  xs={6}
                  sx={{
                    alignItems: "center",
                    textAlign: "center",
                  }}
                  key={i}
                >
                  <Box
                    sx={{
                      gap: 1,
                    }}
                  >
                    <Button
                      sx={{
                        p: 3,
                        display: "flex",
                        justifyContent: "left",
                      }}
                      href={icon.href}
                      style={{ borderRadius: 0 }}
                    >
                      {icon.iconElement}
                      <Typography sx={{ ml: 1 }}>{icon.label}</Typography>
                    </Button>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          <Box sx={{ display: "flex", justifyContent: "center", pt: 3 }}>
            <Button href="/" variant="outlined" style={{ borderRadius: 0 }}>
              Más información...
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
