import { Box, Divider, Fade, Typography, Grow } from "@mui/material";
import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

export const Experience: React.FC<{}> = () => {
  const [isVisible, setIsVisible] = useState(false);
  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  const infoExperience = [
    {
      title: "EMPOPASTO S.A. E.S.P",
      content:
        "Auxiliar jurídico en el área de Cartera de la Sugerencia Comercial (Proyección de Resoluciones de Terminaciones de Contrato de Prestación de Servicios Públicos Domiciliarios, contestación de Peticiones, Quejas y Reclamos, apoyo a los abogados de Cartera en Demandas Ejecutivas para recuperación de Cartera, Proyección de documentos con contenido Jurídico y demás que sean encargadas por el supervisor del contrato)",
      date: "Agosto de 2017 – Julio de 2018",
    },
    {
      title: "INGELEC S.A.S.",
      content:
        "Profesional Jurídico (Proyección de Respuestas y oficios a entidades privadas o públicas, elaboración de los contratos laborales de la empresa, representación en procesos judiciales, elaboración de oficios de respuesta a Ministerio del Trabajo, UGPP, entre otras funciones)",
      date: "Septiembre de 2019 – Octubre de 2019",
    },
    {
      title: "FUNDACIÓN CENTRO INTEGRAL “SER”",
      content:
        "Asesor Jurídico (Asesorías jurídicas en relaciones laborales, funciones, manejo de la normatividad vigente en materia de salud. Elaboración de contratos de prestación de servicios y representación judicial en general).",
      date: "Noviembre de 2019 – Octubre de 2020",
    },
    {
      title: "EUGENIO JAVIER ACOSTA HUERTAS – ABOGADOS PSR",
      content:
        "Asesor Jurídico adscrito a oficina de abogados, litigante en procesos laborales y de la seguridad social, civiles y administrativos. Proyección de demandas laborales, trámites administrativos, acciones de tutela, etc.",
      date: "Octubre de 2020 – Actual",
    },
  ];
  

  const infoExperienceReverse = infoExperience.reverse();

  return (
    <Box pt={10} id="experience">
      <VisibilitySensor onChange={onVisibilityChange}>
        <Fade in={isVisible} timeout={2000}>
          <Divider
            textAlign="left"
            sx={{
              pb: 3,
              "&::before, &::after": {
                borderColor: "#BCA456",
              },
            }}
          >
            <Typography m={2} variant="h3">
              Experiencia
            </Typography>
          </Divider>
        </Fade>
      </VisibilitySensor>
      <VisibilitySensor onChange={onVisibilityChange}>
        <Box>
          {infoExperienceReverse.map((exp, index) => {
            return (
              <Grow key={index} in={isVisible} timeout={(index + 2) * 1000}>
                <Box py={2}>
                  <Typography py="5px" variant="h5" color="white">
                    {exp.title}
                  </Typography>
                  <Typography variant="h6" color="#bbb">
                    {exp.content}
                  </Typography>
                  <Typography
                    pt="5px"
                    fontStyle="oblique"
                    variant="subtitle1"
                    color="#888"
                  >
                    {exp.date}
                  </Typography>
                </Box>
              </Grow>
            );
          })}
        </Box>
      </VisibilitySensor>
    </Box>
  );
};
