import {
  AppBar,
  Box,
  Container,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  Button,
} from "@mui/material";
import React from "react";
// import { themePalette } from "../config/theme.config";
import { NavDrawer } from "./NavDrawer";

export const NavBar: React.FC<{}> = () => {
  const showButtons = useMediaQuery("(min-width:960px)");

  const AppBarStyle = {
    background: `linear-gradient(to bottom, rgba(12,14,32,1), rgba(12,14,26,0.95))`
  };

  const [tabValue, setTabValue] = React.useState(0);

  const handleTab = (event: React.SyntheticEvent, newValue: number) => {
    let sectionName = "";
    if (newValue === 1) sectionName = "about";
    if (newValue === 2) sectionName = "services";
    if (newValue === 3) sectionName = "experience";
    if (newValue === 4) sectionName = "contact";
    const section = document.getElementById(sectionName);
    section?.scrollIntoView({ behavior: "smooth" });
    setTabValue(newValue);
  };

  const buttonHomeHandler = () => {
    setTabValue(0);
    setTimeout(() => {
      const section = document.getElementById("home");
      section?.scrollIntoView({ behavior: "smooth" });
    }, 350);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar style={AppBarStyle} position="fixed">
        <Toolbar>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignContent="center"
              textAlign="center"
              alignItems="center"
            >
              <Grid item>
                <Button
                  onClick={buttonHomeHandler}
                  variant="outlined"
                  size="small"
                  value="home"
                  style={{ borderRadius: 0 }}
                >
                  <Typography variant="h6">
                    Richard Velásquez
                  </Typography>
                </Button>
              </Grid>
              <Grid item>
                <Stack direction="row" spacing={2}>
                  {showButtons && (
                    <>
                      <Tabs value={tabValue} onChange={handleTab} centered>
                        <Tab
                          hidden
                          label=""
                          sx={{
                            fontSize: "16px",
                            display: "none",
                          }}
                        />
                        <Tab sx={{ fontSize: "16px", textTransform: "none" }} label="Sobre Mí" />
                        <Tab sx={{ fontSize: "16px", textTransform: "none" }} label="Servicios" />
                        <Tab sx={{ fontSize: "16px", textTransform: "none" }} label="Experiencia" />
                        <Tab sx={{ fontSize: "16px", textTransform: "none" }} label="Contacto" />
                      </Tabs>

                      <Button size="large" href="/HojaDeVida2023.pdf"><b>Hoja de Vida</b></Button>
                    </>
                  )}
                </Stack>
                {!showButtons && <NavDrawer></NavDrawer>}
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
};
