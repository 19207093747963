import { Box, Fade, Divider, Typography } from "@mui/material";
import { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

function About() {
  const [isVisible, setIsVisible] = useState(false);
  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setIsVisible(true);
    }
  };

  return (
    <Box pt={10} id="about">
      <VisibilitySensor onChange={onVisibilityChange}>
        <Fade in={isVisible} timeout={1000}>
          <Divider
            sx={{
              "&::before, &::after": {
                borderColor: "#BCA456",
              },
            }}
            textAlign="left"
          >
            <Typography m={2} variant="h3">
              Sobre Mí
            </Typography>
          </Divider>
        </Fade>
      </VisibilitySensor>
      <VisibilitySensor onChange={onVisibilityChange}>
        <Fade in={isVisible} timeout={1900}>
          <Box>
            <Typography pt={5} variant="h6" textAlign="justify">
              Soy abogado graduado de la Universidad de Nariño. Además, cuento
              con una especialización en Instituciones Jurídico-Procesales
              obtenida en la Universidad Nacional de Colombia. A lo largo de mi
              carrera profesional, he adquirido una amplia experiencia en el
              campo del Derecho Laboral y de la Seguridad Social, que me ha
              permitido alcanzar un conocimiento constante en la materia. Me
              considero una persona proactiva, responsable y dedicada en el
              ejercicio de mi profesión. Mi enfoque se centra en ser eficiente
              en todas mis labores. Disfruto trabajando en equipo, ya que creo
              firmemente en la importancia de la colaboración y la sinergia para
              lograr resultados óptimos. Además, siempre busco mantenerme
              actualizado en los avances y cambios en mi área de
              especialización, ya que considero que la actualización constante
              de conocimientos es fundamental para brindar un servicio de
              calidad.
            </Typography>
            <Typography pt={2} variant="h6" textAlign="justify">
              Mi enfoque en cada caso que emprendo es siempre el mismo: brindar
              a mis clientes una representación legal sólida y eficaz,
              manteniendo un enfoque práctico y orientado a los resultados. Me
              tomo el tiempo de entender cuidadosamente los objetivos y
              preocupaciones de mis clientes, trabajando estrechamente con ellos
              para desarrollar una estrategia legal a medida, que satisfaga sus
              necesidades específicas. Como abogado, he dedicado mi carrera a la
              defensa de la justicia y la equidad en un amplio abanico de casos,
              incluyendo además de la representación en procesos laborales y de
              la seguridad social, también en disputas comerciales, casos de
              lesiones personales y disputas administrativas de reparación
              directa y nulidad y restablecimiento del derecho, todo lo anterior
              tanto a empresas como a particulares. En mi tiempo libre, me
              apasiona mantenerme al tanto de las últimas tendencias legales y
              participar en actividades comunitarias. De igual forma, disfruto
              de la lectura y la escritura, lo que me permite mantener mi mente
              aguda y creativa. Si necesita asesoramiento legal o representación
              en un caso, no dude en contactarme. Estoy aquí para asistirlo en
              cualquier problema legal que pudiera surgir.
            </Typography>
          </Box>
        </Fade>
      </VisibilitySensor>
    </Box>
  );
}

export default About;
