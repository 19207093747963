import { Box, Divider, Typography } from "@mui/material";
import React from "react";

export const Footer: React.FC<{}> = () => {
  return (
    <Box py={2}>
      <Divider />
      <Typography my={2} variant="body1">
        © 2024 Richard Velásquez
      </Typography>
    </Box>
  );
};
