/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import {
  Drawer,
  Box,
  IconButton,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState } from "react";

export const NavDrawer: React.FC<{}> = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const [tabValue, setTabValue] = React.useState("home");

  const drawerButtonHandler = (sectionId: string) => {
    const section = document.getElementById(sectionId);
    section?.scrollIntoView({ behavior: "smooth" });
    setTabValue(sectionId);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="logo"
        onClick={() => {
          setIsDrawerOpen(true);
        }}
      >
        <MenuIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        color="red"
      >
        <Box width="200px" m={1} gap={0.5}>
          <List>
            <Button onClick={() => setIsDrawerOpen(false)}>
              <ArrowBackIcon />
            </Button>

            <ListItem disablePadding>
              <ListItemButton onClick={() => drawerButtonHandler("about")}>
                <Typography>Sobre mí</Typography>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => drawerButtonHandler("services")}>
                <Typography>Servicios</Typography>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => drawerButtonHandler("experience")}>
                <Typography>Experiencia</Typography>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton onClick={() => drawerButtonHandler("contact")}>
                <Typography>Contacto</Typography>
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding>
              <ListItemButton href="/HojaDeVida2023.pdf">
                <Typography color="#BCA456">Hoja de Vida</Typography>
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </>
  );
};
