import { Container } from "@mui/material";
import About from "../components/About";
import Contact from "../components/Contact";
import { Experience } from "../components/Experience";
import { Footer } from "../components/Footer";
import { NavBar } from "../components/NavBar";
import { Services } from "../components/Services";
import { HeaderLogo } from "../components/HeaderLogo";
import '../App.css';

export default function Home() {
  return (
    <div className="App">
      <NavBar></NavBar>
      <Container>
        <HeaderLogo />
        <About></About>
        <Services></Services>
        <Experience />
        <Contact></Contact>
        <Footer></Footer>
      </Container>
    </div>
  );
}
